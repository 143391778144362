import React from "react";

export default props => {
  const welcome = props.hasVisited ? "Welcome back" : "Hello";
  
  return (
    <h1 className="title">
      {welcome} {props.platform} user
    </h1>
  );
};
