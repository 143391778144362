// Get information from IP
var googleLocationKey = "AIzaSyB-TboQdqk_FqQvpPi7S1nxYQhhf0d0IlQ";
var openCageKey = "08326ae4f93f4642a059b4f9edb6539e";
var openCageApi = "https://api.opencagedata.com/geocode/v1/json";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

export const getWeather = async position => {
  const rawResponse = await fetch("/api/weather", {
    method: "POST",
    headers,
    body: JSON.stringify(position)
  });
  const content = JSON.parse(await rawResponse.json());
  return {
    summary: content.currently.summary.toLowerCase(),
    forecast: content.daily,
    temperature: content.currently.temperature
  };
};

const getIP = async () => {
  const rawResponse = await fetch("https://api.ipify.org/?format=json");
  return await rawResponse.json();
};

export const getNetwork = async () => {
  const rawResponse = await fetch("/api/network", {
    method: "POST",
    headers,
    body: JSON.stringify(await getIP())
  });
  const content = JSON.parse(await rawResponse.json());

  return {
    provider: content.isp,
    organisation: content.org,
    ip: content.query
  };
};

export const getLocation = () => {
  return new Promise((resolve, reject) => {
    var googleLocationRequest = new XMLHttpRequest();
    googleLocationRequest.onreadystatechange = function() {
      if (
        googleLocationRequest.readyState === 4 &&
        googleLocationRequest.status === 200
      ) {
        let estimatedLocation = JSON.parse(googleLocationRequest.responseText)
          .location;
        let latitude = estimatedLocation.lat;
        let longitude = estimatedLocation.lng;

        var upenCageUrl =
          openCageApi +
          "?" +
          "key=" +
          encodeURIComponent(openCageKey) +
          "&q=" +
          encodeURIComponent(latitude) +
          "," +
          encodeURIComponent(longitude) +
          "&pretty=1" +
          "&no_annotations=1";

        var openCageRequest = new XMLHttpRequest();
        openCageRequest.open("GET", upenCageUrl, true);

        openCageRequest.onload = function() {
          if (openCageRequest.status === 200) {
            var data = JSON.parse(openCageRequest.responseText);
            const { county, town, suburb } = data.results[0].components;
            resolve({
              county,
              town,
              suburb,
              ...estimatedLocation
            });
          } else if (openCageRequest.status <= 500) {
            console.log(
              "unable to geocode! Response code: " + openCageRequest.status
            );
            var data = JSON.parse(openCageRequest.responseText);
            console.log(data.status.message);
          } else {
            console.log("server error");
          }
        };

        openCageRequest.onerror = function() {
          // There was a connection error of some sort
          console.log("unable to connect to server");
          reject(new Error("unable to connect to server"));
        };

        openCageRequest.send();
      }
      if (
        googleLocationRequest.readyState === 4 &&
        (googleLocationRequest.status === 403 ||
          googleLocationRequest.status === 500)
      ) {
        reject(new Error("Google Location API quota exceeded"));
      }
    };

    googleLocationRequest.open(
      "POST",
      "https://www.googleapis.com/geolocation/v1/geolocate?key=" +
        googleLocationKey,
      true
    );

    googleLocationRequest.send();
  });
};
