import React from "react";
import moment from "moment";

moment.updateLocale("en", {
  calendar: {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: 'dddd',
    sameElse: 'dddd'
  }
});

const calcForecast = forecast => {
  let rainyDay = moment()
    .add(5, "days")
    .calendar();

  if (forecast && forecast.data) {
    const removeToday = forecast.data.slice(1);
    removeToday.forEach((day, index) => {
      if (day.precipType === "rain") {
        rainyDay = moment.unix(day.time).calendar();
      }
    });
  }

  return `on ${rainyDay}`;
};

export default props => {
  // console.log(props.location);
  const rainyDay = calcForecast(props.forecast);

  return (
    <p>
      It’s {Math.floor(props.temperature)}°C and {props.summary} now, but it’s
      going to rain {rainyDay}. Therefor we want to show you this ad.
    </p>
  );
};
