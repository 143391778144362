import React, { Component } from "react";
import ReactMapGL from "react-map-gl";
import "./scss/App.scss";
import { getLocation, getWeather, getNetwork } from "./helpers/api";
import Offering from "./components/Offering";
import YourData from "./components/YourData";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      platform: "",
      position: {},
      weather: {},
      logins: [],
      network: {}
    };
  }

  async componentDidMount() {
    this.getLocalStorage();
    this.getPlatform();
    const position = await getLocation();
    const weather = await getWeather(position);

    this.setState({
      position,
      weather,
      loading: false
    });
  }

  getLocalStorage() {
    const lastSuggested = localStorage.getItem("productSuggestion");
    const productSuggestion =
      lastSuggested === "umbrella" ? "loopbricks" : "umbrella";
    const hasVisited = localStorage.getItem("hasVisited");

    localStorage.setItem("hasVisited", true);
    localStorage.setItem("productSuggestion", productSuggestion);

    this.setState(state => {
      return { hasVisited, productSuggestion };
    });
  }

  getPlatform() {
    var parser = new window.UAParser();
    const { browser, os } = parser.getResult();
    
    const platform = os.name || browser.name;
    this.setState({ platform });
  }

  render() {
    return (
      <div className="App">
        <header>
          <div className="logo">
            <span className="daresay">Daresay</span>
            <span className="labs">labs</span>
          </div>
        </header>
        <div className="intro">
          <YourData
            platform={this.state.platform}
            position={this.state.position}
            weather={this.state.weather}
            position={this.state.position}
          />
        </div>
        <Offering />
      </div>
    );
  }
}

export default App;
