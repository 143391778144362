import React, { Component } from "react";
import "../scss/YourData.scss";
import umbrella from "../images/umbrella.png";
import Map from "./Map";
import WelcomeText from "./WelcomeText";
import WeatherText from "./WeatherText";

export default class MapOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  omMapReady() {
    // console.log('omMapReady');
    setTimeout(() => {
      this.setState({ showInfo: true });
    }, 500);
  }

  render() {
    const location = this.props.suburb || this.props.town || this.props.county;

    return (
      <div className="your-data-wrapper">
        <Map
          latitude={this.props.position.lat}
          longitude={this.props.position.lng}
          onZoomedIn={this.omMapReady.bind(this)}
        />
        <div className={`info-wrapper ${this.state.showInfo && "show"}`}>

          <WelcomeText platform={this.props.platform} />
          
          <div className="info-box">
            <div className="info-text">

              <img className="show-if-desktop" src={umbrella} />

              <p>We know where you are.</p>
              
              <WeatherText
                location={location}
                temperature={this.props.weather.temperature}
                summary={this.props.weather.summary}
                forecast={this.props.weather.forecast}
              />
              
              <img className="show-if-mobile" src={umbrella} />

              <p>
                We already know this about you. What do you know about your
                customers?
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
