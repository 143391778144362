import React, { Component } from "react";
import ReactMapGL from "react-map-gl";
import "../scss/Map.scss";
const TOKEN =
  "pk.eyJ1IjoidmlrdG9yZyIsImEiOiJjanNuOHJmZW0wOHN3NDlyMjh6bHR6b3hoIn0.1kbEO6sa26v1bZHMAILBRA";
const mapStyle = "mapbox://styles/viktorg/cjsna0gqj2sz81fqzb4bexanp";

export default class Map extends Component {
  constructor(props) {
    super(props);
    const isMobile = window.screen.width <= 768;

    this.state = {
      zoom: isMobile ? 12 : 1,
      loaded: false
    };
  }

  zoom() {
    if (this.state.zoom <= 12) {
      setTimeout(() => this.zoom(), 500);
      if (this.props.latitude) {
        this.setState(state => {
          return { zoom: state.zoom + 1 };
        });
      }
    } else {
      this.props.onZoomedIn();
    }
  }

  onLoad() {
    this.zoom();
  }

  render() {
    return (
      <div className="map-wrapper">
        <ReactMapGL
          width={"100%"}
          height={"100%"}
          latitude={this.props.latitude}
          longitude={this.props.longitude}
          mapStyle={mapStyle}
          zoom={this.state.zoom}
          mapboxApiAccessToken={TOKEN}
          onLoad={this.onLoad.bind(this)}
        />
        <div className="map-overlay" />
      </div>
    );
  }
}
