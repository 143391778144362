import React, { Component } from "react";
import david from "../images/david.png";

class Offering extends Component {
  constructor(props) {
    super(props);

    this.state = { value: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(key, event) {
    this.setState({ [key]: event.target.value });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const payload = {
      address: this.state.email,
      name: this.state.company
    };
    const rawResponse = await fetch("/api/mail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    try {
      const res = await rawResponse.json();
      console.log(res);
      if (res.message === 'success') {
        alert("Email sent! Check your inbox (and your span folder)");
      } else {
        alert("Something went wrong!");
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong!");
    }
  };

  render() {
    return (
      <section className="offering">
        {/*
              3/4 offering list down to tablet portrait
              1/4 Contact us down to 
            */}
        <div className="top">
          <div className="offers-headline">
            <h1 className="page-title">Daresay Data Report</h1>
          </div>
        </div>

        <div className="bottom">
          <div>
            <h2>Demo</h2>
            <form onSubmit={this.handleSubmit} className="report-form">
              <label>
                <p>Email:</p>
                <input
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this, "email")}
                />
              </label>
              <br />
              <label>
                <p>Company name (optional):</p>
                <input
                  type="text"
                  value={this.state.company}
                  onChange={this.handleChange.bind(this, "company")}
                />
              </label>
              <br />
              <input type="submit" value="Send" />
            </form>

            <h2>What</h2>
            <p>
              It’s an email, sent once a week, that show you how your product is
              doing.​ ​
            </p>
            <p>
              No login to remember, no new tools to learn, no excel-sheets to
              download.
            </p>

            <h2>Why</h2>
            <ul>
              <li>Keep track of your product over time​</li>
              <li>
                Your data is presented in a way that make sense to you.
                Connected to your business goals​
              </li>
              <li>
                Get actionable recommendations from us that will help you
                improve your product​
              </li>
              <li>Be proactive by seeing trends in user behaviour</li>
            </ul>
          </div>

          <div className="contact">
            <img src={david} alt="David Furendal" className="center" />
            <h2>David Furendal</h2>
            <p className="title">Head of labs</p>
            <p className="phone">
              <a href="tel:+46761344367">+46 761 344 367</a>
            </p>
            <p className="email">
              <a href="mailto:david.furendal@daresay.co">
                david.furendal@daresay.co
              </a>
            </p>
            <p className="website">
              <a href="http://daresay.co">daresay.co</a>
            </p>
            <p className="mission">
              We’re an award-winning customer-centric experience design and
              technology agency based in Stockholm, Sweden.
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default Offering;
